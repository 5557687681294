// Please note: Import every new color in megatron.scss and theme.js files also
:root {
  --brand_primary: #0052cc;
  --brand_black: #000;
  --brand_white: #fff;

  // Primary Colors
  --primary_08: #001029;
  --primary_16: #002152;
  --primary_24: #00317a;
  --primary_32: #0041a3;
  --primary_40: #0052cc;
  --primary_48: #0062f5;
  --primary_54: #007aff;
  --primary_56: #1f78ff;
  --primary_64: #4791ff;
  --primary_72: #70a9ff;
  --primary_80: #99c2ff;
  --primary_88: #c2daff;
  --primary_96: #ebf3ff;
  --primary_02: #95a0b2;
  --primary_04: #333b47;
  --primary_03: #67768e;
  --primary_01: #454f5f;
  --primary_05: #7d8ba1;
  --person_notification_bg: #c25c1a;
  --person_notification_border: #421664;
  --person_notification_bg_events: #f6eefb;
  --event_vech_bg: #f5e9ff;
  --event_shock_bg: #f0fbee;
  --event_shock_border: #1e5915;
  --event_tampering_bg: #fae7ff;
  --event_tampering_border: #7a048d;
  --category-color: '#797A7B';
  --event_person_br: #0041a3;

  // Neutrals-Hanwha
  --hGreyscale_08: transparent;
  --hGrayscale_16: transparent;
  --hGrayscale_24: #333;
  --hGrayscale_32: #3c3d3e;
  --hGreyscale_40: #474849;
  --hGreyscale_48: transparent;
  --hGreyscale_56: transparent;
  --hGreyscale_64: #8d8f90;
  --hGreyscale_72: #acb5c3;
  --hGreyscale_80: #c9cdcf;
  --hGreyscale_88: #dfe1e2;
  --hGrayscale_96: transparent;

  // Neutrals
  --greyscale_08: #111418;
  --grayscale_16: #222830;
  --grayscale_24: #333;
  --grayscale_32: #454f5f;
  --greyscale_40: #566376;
  --greyscale_48: #67768e;
  --greyscale_56: #7d8ba1;
  --greyscale_64: #95a0b2;
  --greyscale_72: #acb5c3;
  --greyscale_80: #c4cad4;
  --greyscale_88: #dcdfe5;
  --grayscale_96: #f3f5f7;
  --greyscale_96: #f3f5f7;
  --greyscale_97: #f6f6f6;

  // Text
  --text_color: #111418;
  --text_color_event: #141414;
  --text_color_obj: #656667;
  --body_color: #7d8ba1;
  --text_disable_color: #c4cad4;

  // Feedback - Success
  --success_24: #27532d;
  --success_48: #4ea65a;
  --success_64: #82c48b;
  --success_80: #badebf;
  --success_96: #f1f8f2;

  // Feedback - Warning
  --warning_24: #734f07;
  --warning_48: #d3a422;
  --warning_64: #e5c261;
  --warning_80: #f1dda7;
  --warning_96: #fcf8ed;

  // Feedback - Error
  --error_24: #641616;
  --error_48: #c92c2c;
  --error_64: #de6868;
  --error_80: #edabab;
  --error_96: #fbeeee;
  --error_112: #ff1f4b;

  // Overlay
  --overlay: #001029;

  // Avatar
  --avatar_dark_grey: #5b7282;
  --avatar_dark_independence: #484c60;
  --avatar_dark_red: #d91f11;
  --avatar_dark_orange: #e86427;
  --avatar_dark_yellow: #f5c518;
  --avatar_dark_lime: #3c7d0e;
  --avatar_dark_teal: #077d55;
  --avatar_dark_turquoise: #167b7d;
  --avatar_dark_aqua: #0073ba;
  --avatar_dark_blue: #186ade;
  --avatar_dark_brand: #0052cc;
  --avatar_dark_ultramarine: #535fe8;
  --avatar_dark_purple: #8f49de;
  --avatar_dark_pink: #cc1d92;
  --avatar_light_grey: #dce3e8;
  --avatar_light_red: #fadcd9;
  --avatar_light_orange: #fcddc7;
  --avatar_light_yellow: #faf6cf;
  --avatar_light_lime: #d5f0b1;
  --avatar_light_teal: #d5f0b1;
  --avatar_light_turquoise: #beebe7;
  --avatar_light_aqua: #c7e8ed;
  --avatar_light_blue: #d4e4fa;
  --avatar_light_brand: #cbe0ff;
  --avatar_light_ultramarine: #dee0fa;
  --avatar_light_purple: #eadcfc;
  --avatar_light_pink: #f7daed;

  // Other
  --list_block_background_color: #f5f6f9;
  --error_block_background_color: #ffebef;
  --warning_block_label_value_color: #916d32;
  --axis_border_timeline: #bfbfbf;
  --transparent_color: transparent;
  --diff_positive: #d9f2f7;
  --diff_text_positive: #10586a;
  --all_result: #001538;
  --tagvlm: #421664;
  --tag_vlm_bg: #f6eefb;
  --tag_orange: #e58161;
  --tag_red: #e56161;
  --tag_blue: #61a6e5;
  --tag_purple: #7461e5;
  --progress_org_device: #e25959;
  --seprator_device: #e0e4e5;
  --error_block_value_color: #111827;
  --bs-breadcrumb-divider-color: #fff;
  --breadcrumb-color1: #515152;
  --button-color-1: #474849;
  --device_error_trail_color: #ffc2ce;
  --custom_color_1: #35b9b2;
  --custom_color_2: #61dafb;
  --custom_color_3: #dc3545;
  --custom_color_4: #ededed;
  --custom_color_5: #d7d7d7;
  --custom_color_6: #04a89f;
  --custom_color_7: #05a89f;
  --custom_color_8: #ddd;
  --custom_color_9: #b0adad;
  --custom_color_10: #545255;
  --custom_color_11: #00aa9f;
  --custom_color_12: #04a9a1;
  --custom_color_13: #959292;
  --custom_color_14: #302e32;
  --custom_color_15: #bcbcbc;
  --custom_color_16: #343947;
  --custom_color_17: #a7aec9;
  --custom_color_18: #4b93fb;
  --custom_color_19: #444648;
  --storage-clode-text: #de671b;
  --custom_color_20: #a10505;
  --custom_color_21: #017c84;
  --custom_color_22: #0041a3;
  --custom_color_disabled: #cccfd1;

  // RGB Colors
  --greyscale-custom1: 0, 0, 0;
  --greyscale-custom2: 0, 16, 41;
  --greyscale-custom3: 255, 255, 255;
  --greyscale-custom4: 100, 22, 22;
  --greyscale-custom5: 20, 24, 29;
  --greyscale-custme6: 0.5, 0, 0.5;
  --greyscale-custme7: 0, 0, 0, 0.05;
  --greyscale-custme8: 0, 0, 0, 0.1;
  --greyscale-custom9: 0, 82, 204, 0.2;
  --greyscale-custom10: 0, 0, 0, 0.4;
  --error80-custom1: 237, 171, 171;
  --error64-custom1: 222, 104, 104;

  // License Colors
  --light_orange: #f8ab7a;
  --dark_orange: #f37321;
  --light_border: #b3abab;
  --slider-red: #de671b;

  // Custom event
  --color-enter-event: #d5b700;
  --color-exit-event: #d58d00;
  --color-tampering-event: #7a048d;
  --color-shock-event: #1e5915;
  --color-appear-disappear-event: #04a1e5;
  --color-loitering-event: #017c84;
  --color-virtual-line-event: #cd0606;
  --color-motion-event: #421664;
  --color-fog-event: #0368b5;
}
